const QUERIES = {
  USERS_LIST: 'users-list',
}
const roleQUERIES = {
  Role_LIST: 'role-list',
  PERMISSION : 'user-permisionv',
  SECTOR : 'user-sector',
  SHD_LST: 'SHD_LST',
  FACILITY: 'FACILITY'
}

const getUserDetails = {
  Userdata: 'users-data',
}

export { QUERIES, roleQUERIES,getUserDetails }
