import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../helpers'
import { UsersQueryResponse } from './_models'
import { json } from 'stream/consumers'

const API_URL = process.env.REACT_APP_API_URL
const GET_SECTOR = `${API_URL}/sectors`

const getUserrolesv2 = (): Promise<UsersQueryResponse> => {
  var dt = axios
    .get(`${GET_SECTOR}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return dt;
}

export { getUserrolesv2 }
