/* eslint-disable react/jsx-no-target-blank */
import { KTSVG } from '../../../helpers'
import { FC, useState, useEffect } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { roleQUERIES, isNotEmpty, toAbsoluteUrl } from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import axios from 'axios'
import { layouts } from 'chart.js'
import { json } from 'stream/consumers'
import { Retryer } from 'react-query/types/core/retryer'
import { Value } from 'sass'
import { useDataLayerValue } from "../../../../DataLayer";
import { getUserrolesv2 } from './_request'


const SidebarFooter = () => {
  const { currentUser, setCurrentUser } = useAuth()
  const [{ sector }, dispatch] = useDataLayerValue();

  const {
    isLoading,
    data: responsexyz,
    error,
  } = useQuery(
    `${roleQUERIES.SECTOR}`,
    () => {
      return getUserrolesv2()
    },

    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true }
  )



  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <select
        className='form-select form-select-solid form-select-lg fw-bold btn btn-flex flex-center'
        name='SectorCode'
        value={sector}
        onChange={(e) => {
          dispatch({
            type: "SET_SECTOR",
            sector: e.target.value,
          });
          // window.location.reload();
          window.location.href = '/dashboard'
        }}
      >
        <option value='none' selected>SELECT</option>
        {responsexyz?.data?.map((value, i) => (
          <option value={value.code} >{value.name}</option>
        ))}


      </select>
      {/*
      <a
        href={process.env.REACT_APP_PREVIEW_DOCS_URL}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Metronic Docs & Components'
      >
        <span className='btn-label'>Docs & Components</span>
        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='btn-icon svg-icon-2 m-0' />
      </a>
      
      */}
    </div>
  )
}

export { SidebarFooter }
