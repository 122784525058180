import axios from 'axios'
import { AuthModel, NewUserRegistrationModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/accounts/loggeduser`
export const LOGIN_URL = `${API_URL}/accounts/admin/token`
export const REGISTER_URL = `${API_URL}/accounts/ResetPasswordConfirm`
export const REQUEST_PASSWORD_URL = `${API_URL}/accounts/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  token: string,
  email: string,
  password: string,
  password_confirmation: string
  
) {
  return axios.post(REGISTER_URL, {
    token,
    email,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

//Registration

export function NewUserRegistration() {
  return axios.get<NewUserRegistrationModel>(REGISTER_URL)
}
