const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return {
        user: null,
        token: null,
        isLogged: false,
        sector: null,
        filter_facility: '',
        SET_PERMISSION_USER: '',
      }
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    // Ignore write errors;
  }
}

export const initialState = loadState()

const reducer = (state, action) => {
  console.log(action)

  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      }

    case 'SET_FILTER_FACILITY':
      return {
        ...state,
        filter_facility: action.filter_facility,
      }
    case 'SET_PERMISSION_USER':
      return {
        ...state,
        SET_PERMISSION_USER: action.SET_PERMISSION_USER,
      }
    case 'SET_TOKEN':
      saveState({
        ...state,
        token: action.token,
      })
      return {
        ...state,
        token: action.token,
      }
    case 'SET_SECTOR':
      saveState({
        ...state,
        sector: action.sector,
      })
      return {
        ...state,
        sector: action.sector,
      }
    default:
      return state
  }
}

export default reducer
