import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../../app/UI/UserProfileView/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../UI/user-management/UsersPage'))
  const UsersPage = lazy(() => import('../../app/UI/user-management/UsersPage'))
  const RolesPage = lazy(() => import('../../app/UI/user-role-management/RolesPage'))
  const Facility = lazy(() => import('../../app/UI/Facilities/viewall'))
  const UserPermission = lazy(() => import('../../app/UI/UserPermission/viewall'))
  const Sector = lazy(() => import('../../app/UI/Sectors/viewall'))
  const Alert = lazy(() => import('../../app/UI/Alerts/viewall'))
  const Tag = lazy(() => import('../../app/UI/Tags/viewall'))
  const ResetConfiguration = lazy(() => import('../../app/UI/Reset-Configuration/viewall'))




  const { currentUser } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='user/account/*'
          element={
            <SuspensedView>
              <AccountPage userid={currentUser?.result.code} />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/facility/*'
          element={
            <SuspensedView>
              <Facility />
            </SuspensedView>
          }
        />
        <Route
          path='apps/permission/*'
          element={
            <SuspensedView>
              <UserPermission />
            </SuspensedView>
          }
        />
        <Route
          path='apps/sector/*'
          element={
            <SuspensedView>
              <Sector />
            </SuspensedView>
          }
        />
        <Route
          path='apps/alert/*'
          element={
            <SuspensedView>
              <Alert />
            </SuspensedView>
          }
        />
        <Route
          path='apps/tag/*'
          element={
            <SuspensedView>
              <Tag />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/config/*'
          element={
            <SuspensedView>
              <ResetConfiguration />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-role-management/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
